<template>
  <div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card title="Pengeluaran Kas">
          <div class="row pl-3 pr-3 pt-3 pb-3">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Faktur</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input
                style="width: 250px;"
                v-model="input.faktur"
                :readOnly="false"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label class="mt-1">Tanggal</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style="width: 150px;"
                v-model="maskInput.tgl"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label class="mt-1">Debet</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-model="input.debet"
                style="width: 100%;"
              >
                <a-select-option
                  v-for="(data, index) in masterKredit"
                  :value="data.id"
                  :key="index"
                  >{{ data.kode + " - " + data.keterangan }}</a-select-option
                >
              </a-select>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label class="mt-1">Kredit</label>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2 mr-2">
              <a-input
                v-model="maskInput.rekening_kredit"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
              <a-input
                v-model="maskInput.keterangan_kredit"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label class="mt-1">Keterangan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                style="width: 70%;"
                v-model="input.keterangan"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <label class="mt-1">Mutasi</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                :options="{ numeral: true }"
                class="ant-input mr-1"
                style="width: 50%;"
                :raw="true"
                v-model="input.mutasi"
                placeholder="Mutasi"
              />
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import Cleave from 'vue-cleave-component'
export default {
  components: {
    cleave: Cleave,
  },
  data() {
    return {
      masterKredit: [],
      input: {
        faktur: '',
        debet: '',
        keterangan: '',
        mutasi: '',
      },
      maskInput: {
        tgl: '',
        rekening_kredit: '',
        keterangan_kredit: '',
      },
    }
  },
  methods: {
    moment,
    async handleSubmit() {
      var fd = this.$g.clone(this.input)
      var res = await lou.customUrlPost2('batalpencairan_kredit', fd)
      if (res) {
        // this.getAllData()
        this.resetForm()
      }
    },
    resetForm() {
      this.input = {
        faktur: '',
        debet: '',
        keterangan: '',
        mutasi: '',
      }
      this.maskInput = {
        tgl: '',
        rekening_kredit: '',
        keterangan_kredit: '',
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
